/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createVCCardPrintout = /* GraphQL */ `mutation CreateVCCardPrintout($values: [String]) {
  createVCCardPrintout(values: $values) {
    key
    errors
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVCCardPrintoutMutationVariables,
  APITypes.CreateVCCardPrintoutMutation
>;
export const createEmail = /* GraphQL */ `mutation CreateEmail(
  $input: CreateEmailInput!
  $condition: ModelEmailConditionInput
) {
  createEmail(input: $input, condition: $condition) {
    id
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEmailMutationVariables,
  APITypes.CreateEmailMutation
>;
export const updateEmail = /* GraphQL */ `mutation UpdateEmail(
  $input: UpdateEmailInput!
  $condition: ModelEmailConditionInput
) {
  updateEmail(input: $input, condition: $condition) {
    id
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEmailMutationVariables,
  APITypes.UpdateEmailMutation
>;
export const deleteEmail = /* GraphQL */ `mutation DeleteEmail(
  $input: DeleteEmailInput!
  $condition: ModelEmailConditionInput
) {
  deleteEmail(input: $input, condition: $condition) {
    id
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEmailMutationVariables,
  APITypes.DeleteEmailMutation
>;
export const sendEmail = /* GraphQL */ `mutation SendEmail(
  $name: String!
  $email: String!
  $subject: String!
  $message: String!
) {
  sendEmail(name: $name, email: $email, subject: $subject, message: $message) {
    value
    errors
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendEmailMutationVariables,
  APITypes.SendEmailMutation
>;
